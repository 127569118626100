const orderCheckIntersection = {
  data() {
    return {
      intersectingEndpoint: '/api/order/intersectingDates',
    };
  },
  methods: {
    async checkIntersecting(businessType) {
      const res = await this.callApi({ url: this.intersectingEndpoint, method: 'get' });
      if (res.data.length > 0) {
        const cType = this.strings.commonApp
          .contract_types?.filter((t) => t.code === businessType)[0]?.title;
        const maxDate = res.data
          .slice(0, 9)
          .map((d) => this.$moment(d, 'YYYY-MM-DD', true).format('DD MMM YYYY'))
          .join(', ');
        const message = this.format(this.strings.commonApp.error_intersection,
          cType, maxDate, cType);
        this.showIntersectionModal({ message, type: 'error', id: 'intersection' });
        throw new Error('dates has intersecting');
      }
    },
    showIntersectionModal({
      message,
      title,
      type,
      id,
    }) {
      const modal = {
        id: `wet-order-intersection-modal-${id || this.uid}`,
        title,
        icon: type || 'info',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            title: this.strings.commonApp?.ok,
            name: `wet-order-intersection-modal-${id || this.uid}-ok`,
            event: ['close'],
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
  },
};

export default orderCheckIntersection;
