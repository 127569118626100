export default {
  au: 'austria',
  be: 'belgium',
  bg: 'bulgaria',
  hr: 'croatia',
  cy: 'cyprus',
  cz: 'czechia',
  dk: 'denmark',
  ee: 'estonia',
  fi: 'finland',
  fr: 'france',
  de: 'germany',
  gr: 'grece',
  hu: 'hungary',
  ie: 'ireland',
  it: 'italy',
  lv: 'latvia',
  lt: 'lithuania',
  lu: 'luxembourg',
  mt: 'malta',
  nl: 'netherlands',
  pl: 'poland',
  pt: 'portugal',
  ro: 'romania',
  sk: 'slovakia',
  si: 'slovenia',
  es: 'spain',
  se: 'sweden',
};
